import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faShoppingCart,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Storage } from "../../Utility/Storage";
import { CartItemKey, BusinessDaysKey } from "../../Utility/Config";
import { storage as cartStorage } from "../../Utility/CartStorage";

class Header extends Component {
  state = {};
  getHeading = () => {
    let nextOpeningTime = this.getBusinessDays();
    return this.props.value.isNoMaxNoSaleOn
      ? "Restaurant Closed, Next Opening " + nextOpeningTime
      : this.props.value.heading;
  };

  getCartIcon = () => {
    return this.props.value.isNoMaxNoSaleOn ? null : (
      <div className={this.getCartClasses()} onClick={this.handleCartIconClick}>
        <FontAwesomeIcon icon={faShoppingCart} />
        <span>{this.getCartCount()}</span>
      </div>
    );
  };

  getBusinessDays = () => {
    let nextOpeningTime;
    let businessDays = Storage.getItem(BusinessDaysKey);
    businessDays = JSON.parse(businessDays);

    if (businessDays) {
      let restaurantNodeId = this.props.value.restaurantNodeId;
      businessDays = businessDays[restaurantNodeId];
      if (businessDays) {
        let currentTime = new Date();
        let currentDayOfWeek = new Date().toLocaleString("en-US", {
          weekday: "long",
        });

        let nextDay = new Date(currentTime.getDate() + 1);
        let nextDayOfWeek = nextDay.toLocaleString("en-US", {
          weekday: "long",
        });

        let businessTimingForCurrentDay = businessDays.find(
          (businessDay) => businessDay.name.toUpperCase() == currentDayOfWeek.toUpperCase()
        );

        let businessTimingForNextDay = businessDays.find(
          (businessDay) => businessDay.name.toUpperCase() == nextDayOfWeek.toUpperCase()
        );
        if (businessTimingForCurrentDay && businessTimingForCurrentDay.startTime) {

          let startTime = new Date(currentTime.toDateString() + " " + businessTimingForCurrentDay.startTime);

          if (currentTime < startTime) {
            nextOpeningTime = "at " + businessTimingForCurrentDay.startTime;
          } else {
            if(businessTimingForNextDay && businessTimingForNextDay.startTime){
              nextOpeningTime = "Tomorrow at " + businessTimingForNextDay.startTime;
            }
          }
        }
      }
    }

    return nextOpeningTime;
  };

  render() {
    return (
      <div className="header">
        <Link to={this.getRoutingURL()}>
          {this.props.value.closeIcon ? (
            <FontAwesomeIcon icon={faTimes} />
          ) : (
            !this.props.isHideBackButton && (
              <FontAwesomeIcon icon={faChevronLeft} />
            )
          )}
        </Link>
        <div className="heading">{this.getHeading()}</div>
        {this.getCartIcon()}
        {/* {this.props.value.hideCart ? null : (
          <div
            className={this.getCartClasses()}
            onClick={this.handleCartIconClick}
          >
            <FontAwesomeIcon icon={faShoppingCart} />
            <span>{this.getCartCount()}</span>
          </div>
        )} */}
      </div>
    );
  }

  handleCartIconClick = () => {
    if (this.props.cartIconClickEvent) {
      this.props.cartIconClickEvent();
    }
  };

  getCartCount = () => {
    let cartItems = JSON.parse(Storage.getItem(CartItemKey));
    if (cartItems) {
      if (cartItems.totalQuantityCount) {
        return cartItems.totalQuantityCount;
      }
    }
    return 0;
  };

  getCartClasses() {
    let classes = "cartIconContainer right";
    classes += this.getCartCount() > 0 ? " background" : "";
    return classes;
  }

  getRoutingURL() {
    const restaurantInfo = cartStorage.getRestaurantInfo();
    let url = "";
    if (restaurantInfo) {
      switch (this.props.value.navigateFrom) {
        case "expanded-menu":
          url = "/pickup/" + restaurantInfo.nodeId.toString();
          break;
        case "pickup":
          let isKioskActive = Storage.checkKioskActive();
          url = isKioskActive ? "/?kiosk=true" : "/";
          break;
        case "customize":
        case "checkout":
          if (
            restaurantInfo.hasOwnProperty("menuNodeId") &&
            restaurantInfo.menuNodeId
          )
            url = "/food-categories/" + restaurantInfo.menuNodeId.toString();
          else url = "/pickup/" + restaurantInfo.nodeId.toString();
          break;
        default:
          break;
      }
    }
    return url;
  }
}

export default Header;
