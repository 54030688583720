import { faFlag, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, useReducer, useRef, useState } from "react";
import { Alert, Overlay, Tab, Tabs, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import CartExpirationDialog from "../../Utility/CartExpirationDialog";
import { storage as cartStorage } from "../../Utility/CartStorage";
import {
  CouponCodeKey,
  IsFromCouponToken,
  KIOSKSetting,
  MembershipKey,
  OrderAgainNumber,
  TableNumber
} from "../../Utility/Config";
import { get } from "../../Utility/HttpService";
import Spinner from "../../Utility/Spinner";
import { Storage } from "../../Utility/Storage";
import {
  checkCartIdExists,
  getRestarurantItemsURL,
  getSettingInfo,
} from "../../Utility/URLService";
import foodIcon from "../../assets/images/food.png";
import Cart from "../../components/Cart/Cart";
import Header from "../Header/NavigationHeader";

const useForceRerender = () => useReducer((state) => !state, false)[1];

const QueueToolTip = (state) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <span ref={target} onClick={() => setShow(!show)}>
        <FontAwesomeIcon icon={faInfoCircle} />
      </span>

      <Overlay target={target.current} show={show} placement="bottom">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            {state.restaurantDetails.rfndCondition}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

class Pickup extends Component {
  state = {
    count: 0,
    navigateFrom: "pickup",
    isQueueTootipOpened: false,
    restaurantDetails: {},
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      count: 0,
      navigateFrom: "pickup",
      isQueueTootipOpened: false,
      restaurantNodeId: props.match.params.restaurantNodeId,
      heading: "SELECT YOUR ITEMS",
      isShowRestaurantWaitingTime: false,
      restaurantWaitingTimeMessage: window.ENV.IsShowRestaurantWaitingTime,
      spinner: {
        show: false,
      },
      cartModal: {
        visible: false,
      },
      Modal: {
        visible: false,
        cartRemoval: false,
        closeEventCallBack: this.expirationModalCloseCallback,
      },
      kioskSetting: JSON.parse(Storage.getItem(KIOSKSetting)),
      screenRefreshTime: 0,
      isRefreshScreen: false,
      isNoMaxNoSaleOn: false,
    };
    this.checkMemberShipValidation();
  }

  componentDidMount() {
    this.checkAndApplyCoupon();
    this.handleOrderAgain();
    this.getQueryParams();
    this.getRestaurantMenuList();
  }
  checkAndApplyCoupon = () => {
    let couponCode = Storage.getItem(CouponCodeKey);
    let isFromCouponToken = Storage.getItem(IsFromCouponToken);
    if (couponCode && isFromCouponToken == "true") {
      this.onViewCart();
    }
  };
  componentWillUnmount() {
    this.state.isRefreshScreen && clearTimeout(this.confirmationTimer);
  }
  handleOrderAgain = () => {
    let orderAgainNumber = JSON.parse(Storage.getItem(OrderAgainNumber));
    if (orderAgainNumber) {
      this.removeCartItemsFromStorage();
    }
  };
  getQueryParams = () => {
    if (window.location.search) {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams) {
        let kioskValue = queryParams.get("kiosk");
        let tableNumber = queryParams.get("table");
        if (kioskValue && kioskValue.toString().toLowerCase() === "true") {
          this.checkAndGetKioskSetting();
        } else if (tableNumber) {
          Storage.setItem(TableNumber, JSON.stringify(tableNumber));
        } else {
          Storage.setItem(TableNumber, JSON.stringify(""));
        }
      }
    } else {
      Storage.setItem(TableNumber, JSON.stringify(""));
      this.checkAndRedirectToMainPage();
    }
  };
  checkAndGetKioskSetting = () => {
    let { kioskSetting, restaurantNodeId } = this.state;
    if (kioskSetting) {
      kioskSetting["IsKioskActive"] = true;
      kioskSetting["RedirectUrl"] = `/pickup/${restaurantNodeId}?kiosk=true`;
      this.SaveKIOSKInfoIntoStorage(kioskSetting);
      Storage.clearPassInformation();
    } else {
      this.getSettingInfo("kiosksetting");
    }
  };
  getSettingInfo = (settingName) => {
    let { restaurantNodeId } = this.state;
    let url = `${getSettingInfo}${settingName}`;
    get(url)
      .then((result) => result.data)
      .then((data) => {
        if (data) {
          let kioskSetting = data;
          if (kioskSetting) {
            kioskSetting["IsKioskActive"] = true;
            kioskSetting[
              "RedirectUrl"
            ] = `/pickup/${restaurantNodeId}?kiosk=true`;
            this.SaveKIOSKInfoIntoStorage(kioskSetting);
            Storage.clearPassInformation();
          }
        }
      });
  };
  SaveKIOSKInfoIntoStorage = (kioskSetting) => {
    this.setState({ kioskSetting }, () => {
      this.checkAndRedirectToMainPage();
    });
    Storage.setItem(KIOSKSetting, JSON.stringify(kioskSetting));
  };
  confirmationTimer = () => {
    this.confirmationTimer = setInterval(() => {
      let { screenRefreshTime } = this.state;
      if (screenRefreshTime === 0) {
        this.refreshScreen();
      } else {
        screenRefreshTime = screenRefreshTime - 1;
        this.setState({ screenRefreshTime });
      }
    }, 1000);
  };
  checkAndRedirectToMainPage = () => {
    let { kioskSetting, screenRefreshTime } = this.state;
    if (kioskSetting && kioskSetting.IsKioskActive) {
      screenRefreshTime = parseInt(kioskSetting.MenuSelection);
      if (screenRefreshTime > 0) {
        this.setState({ screenRefreshTime }, () => {
          this.confirmationTimer();
        });
      }
    }
  };
  refreshScreen = (toMainUrl = false) => {
    let redirectURL = toMainUrl ? "/" : Storage.getRedirectURL();
    if (redirectURL && redirectURL.includes("/pickup/")) this.closeAllModal();
    this.setState({ isRefreshScreen: true }, () => {
      this.removeCartItemsFromStorage();
      Storage.clearPassInformation();
      this.props.history && this.props.history.push(redirectURL);
    });
  };
  removeCartItemsFromStorage = () => {
    cartStorage.removeAll();
  };
  closeAllModal = () => {
    this.onCartScreenClose();
  };
  checkAndRefreshScreenTime = () => {
    let { screenRefreshTime, kioskSetting } = this.state;
    if (kioskSetting && kioskSetting.IsKioskActive) {
      screenRefreshTime =
        parseInt(kioskSetting.MenuSelection) > 0
          ? parseInt(kioskSetting.MenuSelection)
          : 0;
      this.setState({ screenRefreshTime });
    }
  };
  showSpinner = (show) => {
    let spinner = this.state.spinner;
    spinner.show = show;
    this.setState({ spinner });
  };

  checkMemberShipValidation = () => {
    const _key =
      this.props.match.params.restaurantNodeId.toString() + MembershipKey;
    const membership = JSON.parse(Storage.getItem(_key));
    if (membership && membership.isValid === false) {
      let redirectURL = Storage.getRedirectURL();
      this.props.history.push(redirectURL);
    }
  };

  getRestaurantMenuList() {
    let nodeId = this.props.match.params.restaurantNodeId;
    if (isNaN(nodeId)) {
      this.refreshScreen(true);
    } else {
      this.showSpinner(true);
      get(getRestarurantItemsURL + "&nodeId=" + nodeId)
      .then((result) => result.data)
      .then((data) => {
            if (data && data.status) {

        let restaurantInfo = data.data;
        Storage.updateRestaurantInfo(JSON.stringify(restaurantInfo));
        this.setState({
          restaurantDetails: restaurantInfo,
          isShowRestaurantWaitingTime:data.data.isShowRestaurantWaitingTime,
          isNoMaxNoSaleOn: restaurantInfo?.noMaxNoSale || !restaurantInfo?.isRestaurantTakingOnlineOrder});
        this.showSpinner(false);
      } else {
        this.refreshScreen(true);
      }
})
        .catch((error) => {
          console.log(error && error.message);
          this.refreshScreen(true);
        });
    }
  }

  onViewCart = () => {
    if (!this.state.isNoMaxNoSaleOn) {
      this.checkAndRefreshScreenTime();
      let cartInfo = cartStorage.info();
      if (cartInfo.totalCount > 0) {
        get(checkCartIdExists + "?cartId=" + cartInfo.cartId)
          .then((result) => result.data)
          .then((data) => {
            if (data.status) {
              // cartId exists
              let cartModal = {
                ...this.state.cartModal,
                visible: true,
              };
              this.setState({ cartModal });
            } else {
              // cartId not exists
              this.showCartRemovalMessage();
            }
          });
      }
    }
  };

  showCartRemovalMessage = () => {
    let Modal = {
      ...this.state.Modal,
      visible: true,
      cartRemoval: true,
    };
    this.setState({ Modal });
  };

  onCartScreenClose = () => {
    let cartModal = {
      ...this.state.cartModal,
      visible: false,
    };
    this.setState({ cartModal });
  };
  expirationModalCloseCallback = (type) => {
    if (this.state.Modal.cartRemoval) {
      cartStorage.reInitCartWhenCartIdInvalid();
    } else {
      let Modal = {
        ...this.state.Modal,
        visible: false,
      };
      this.setState({ Modal });
    }
    if (type === "Expired") {
      let redirectURL = Storage.getRedirectURL();
      this.props.history.push(redirectURL);
    }
  };
  hideBackButton = () => {
    let { kioskSetting } = this.state;
    let redirectURL = Storage.getRedirectURL();
    return kioskSetting &&
      kioskSetting.IsKioskActive &&
      redirectURL &&
      redirectURL.includes("pickup")
      ? true
      : false;
  };
  render() {
    return (
      <>
        <Spinner
          show={this.state.spinner.show}
          value={this.state.spinner}
        ></Spinner>
        {this.state.cartModal.visible && (
          <Cart
            onCartScreenClose={this.onCartScreenClose}
            checkAndRefreshScreenTime={this.checkAndRefreshScreenTime}
          ></Cart>
        )}
        {this.state.Modal.visible && (
          <CartExpirationDialog modal={this.state.Modal} />
        )}
        <div id="pickup">
          <Header
            value={this.state}
            isHideBackButton={this.hideBackButton()}
            cartIconClickEvent={this.onViewCart}
          />
          <div className="restroName">
            <p>
              {this.state.restaurantDetails &&
                this.state.restaurantDetails.restaurantName}
            </p>
            <p className="readyBy">
              {this.state.isShowRestaurantWaitingTime
                ? this.state.restaurantWaitingTimeMessage
                : this.state.restaurantDetails &&
                  "Ready " + this.state.restaurantDetails.pickupTime}
              {this.state.restaurantDetails && (
                <QueueToolTip
                  restaurantDetails={this.state.restaurantDetails}
                />
              )}
            </p>
          </div>
          {this.state.restaurantDetails && this.getTab()}
        </div>
      </>
    );
  }

  getActiveTabNodeId = () => {
    const foodItemCategories = this.state.restaurantDetails.foodItemCategories;
    const defaultItem = foodItemCategories.find((item) => {
      return item.isDefaultSelection === true;
    });
    if (defaultItem) {
      return defaultItem.nodeId;
    } else {
      return "";
    }
  };
  onTabChange = () => {
    this.checkAndRefreshScreenTime();
  };
  getTab() {
    const activeTabNodeId = this.getActiveTabNodeId();
    return (
      <Tabs
        defaultActiveKey={activeTabNodeId}
        id="uncontrolled-tab-example"
        onSelect={() => {
          this.onTabChange();
        }}
      >
        {this.state.restaurantDetails.foodItemCategories.map((foodoption) => {
          return (
            <Tab
              key={foodoption.nodeId}
              eventKey={foodoption.nodeId}
              title={foodoption.name}
            >
              {this.state.isNoMaxNoSaleOn 
                ? this.restaurantClosedAlert()
                :!foodoption.isAvailable
                ? this.notAvailableAlert(foodoption.name)
                : this.otherRestaurantItemsExistsAlert()
              }
              {foodoption.foodItems.map((option) => {
                return (
                  <Link
                    key={option.nodeId}
                    to={`/food-categories/${option.nodeId}`}
                  >
                    <p>
                      <img width="31" src={foodIcon} alt="food icon"></img>
                      {option.name}
                    </p>
                  </Link>
                );
              })}
            </Tab>
          );
        })}
      </Tabs>
    );
  }

  notAvailableAlert(item) {
    return (
      <Alert>
        <FontAwesomeIcon icon={faFlag} />
        {item} is currently not available
      </Alert>
    );
  }
  restaurantClosedAlert(){
    return(
      <Alert>
        <FontAwesomeIcon icon={faFlag}/>
         Restaurant closed
      </Alert>
    )
  }

  otherRestaurantItemsExistsAlert() {
    let cartInfo = cartStorage.info();
    let restaurantInfo = cartStorage.getRestaurantInfo();
    if (
      cartInfo.restaurantNodeId === 0 ||
      cartInfo.restaurantNodeId === restaurantInfo.nodeId ||
      cartInfo.totalPrice === 0
    ) {
      return <></>;
    } else {
      console.log(JSON.stringify(cartInfo));
      return (
        <Alert>
          <FontAwesomeIcon icon={faFlag} />
          You have items in your cart from a different restaurant.
        </Alert>
      );
    }
  }
}
export default Pickup;
