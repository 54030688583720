import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { Component, Fragment } from "react";
import { Button } from "react-bootstrap";

class ApplyCouponInfoDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponCode: "",
    };
  }

  handleApplyCouponCode = () => {
    let { couponCode } = this.state;
    if (couponCode) this.props.onCouponApplied(couponCode);
    else this.props.showToasterMessage("Please enter the coupon code", "e");
  };

  handleChangeCouponCode = (event) => {
    this.setState({ couponCode: event.target.value });
    this.props.checkAndRefreshScreenTime();
  };

  render() {
    return (
      <Fragment>
        <Dialog
          id="applyCouponInfo"
          maxWidth={false}
          onClose={this.props.onClose}
          aria-labelledby="simple-dialog-title"
          open
        >
          <DialogTitle id="simple-dialog-title" className="text-center">
            <div className="center-align">
              <FontAwesomeIcon
                className="modal-close"
                icon={faTimes}
                onClick={this.props.onClose}
              />
              <div id="headerText">Apply Coupon</div>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="couponCode">
              <input
                type="text"
                value={this.state.couponCode}
                onChange={this.handleChangeCouponCode}
                placeholder="Enter Your Coupon Code"
              />
              <Button
                className="applyCouponBtn"
                variant="danger"
                onClick={this.handleApplyCouponCode}
              >
                Apply Code
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}
export default ApplyCouponInfoDialog;
